<template>
  <span class="footnote">
    <Modal
      ref="footnoteModal"
      class="modal__footnote"
      :on-open="getFootnotes"
      title="Information"
      :data-cy="`${dataCy}__modal`"
      disable-link
    >
      <div>
        <Loading v-if="loading" />
        <div v-else>
          <p
            v-for="(paragraph, index) in paragraphs"
            :key="index"
            class="paragraph"
          >
            {{ paragraph }}
          </p>
        </div>
        <ErrorMessage v-if="error" :html="errorMessage">
          <a class="link" @click.prevent.stop="getFootnotes">{{
            retryLinkText
          }}</a>
        </ErrorMessage>
      </div>
    </Modal>
    <sup :data-cy="`${dataCy}__modal-open`" class="super super-footnote">
      <ButtonComponent
        v-bind="$attrs"
        ref="modalLink"
        button-type="look-like-link"
        :data-cy="`${dataCy}__open-link`"
        :clickhandler="open"
        aria-haspopup="dialog"
        class="footnote__button"
      >
        &#9432;
        <span class="visually-hidden">Weitere Informationen</span>
      </ButtonComponent>
    </sup>
  </span>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { messages } from 'src/lib/messages'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import ErrorMessage from 'src/components/common/error_message/error_message.vue'
import Loading from '../spinner/loading.vue'
import Modal from 'src/components/common/modal/modal.vue'

export default {
  name: 'FootnoteTooltip',
  components: {
    Modal,
    ErrorMessage,
    ButtonComponent,
    Loading
  },
  props: {
    footnoteId: {
      type: [String, Number],
      required: true
    },
    dataCy: {
      type: String,
      default: 'footnote'
    },
    tariffSlug: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      loading: false,
      errorMessage: messages.generic_api_error,
      retryLinkText: 'Erneut versuchen'
    }
  },
  computed: {
    ...mapState({
      error: state => state.footnote.error
    }),
    ...mapGetters({
      paragraphs: 'footnote/getParagraphs'
    })
  },
  methods: {
    async getFootnotes() {
      this.loading = true
      await this.$store.dispatch('footnote/getFootnote', this.footnoteId)
      this.loading = false
    },
    async open() {
      await this.$refs.footnoteModal.open(this.$refs.modalLink)
    }
  }
}
</script>

<style lang="scss" scoped>
.super-footnote {
  font-size: 90%;
  font-weight: bold;
}

.footnote__button {
  height: 0.96rem;
  width: 0.91rem;

  &:focus {
    border-radius: 50%;
  }

  :deep(.button-label) {
    font-size: 90%;
    font-weight: bold;
  }
}
</style>
